import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Avaleht from "./pages/Webpage1Avaleht";

var baseURL = 'https://helenimaja.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiMzg4ODc1YWVjNGIzZWZmMDM2NDI0MjIwNzg2Y2RkNzUwNjU2MWY3MjM5YzljMTk2NWQ5ZTIyZDgyZjhiNmI0MWNjNDFlMzZhZGI2YTU2NjYiLCJpYXQiOjE3MjY1NzE0NzUuNzkzOTk1LCJuYmYiOjE3MjY1NzE0NzUuNzkzOTk4LCJleHAiOjE3MjY2NTc4NzUuNzkxMDIyLCJzdWIiOiI2MyIsInNjb3BlcyI6W119.Y2o7vF5AGHgR-7Ab5LATC77jSRr_sfdHHWOe0syn4mlCR2oTEHwmHg1sZ5QSxF0Y90DfnC_EaKBeupEZalRJI7snTyjUf9DLDUynjjMk-dwowbO0WGEkmMMivgYb2d5QK_8WSFNUwsSeJlxj4F7gPoQM1cqyUpTq5pJuIo09XEBcI4b8pqK-pAd92_IAa-pna_AqG8MqLe6U4yHKSlyJis-EU1GMt4doJ84AlbFd2UZnk2pclRs_zn1wc41NSS54CG0C7UYmUBhjsTvska5TobTLS5CUilvXfkvqLHEcJBErJtiSdXUCySz16zWzWyUrl9CB8DD5dDSB-4B4Bcfxwzdr-ESY6zUlhyPMO8BwOJAxrdSmYF7clyjBGbDnHRH1VylwnPbAoBWImblnPDWCnInJWsJdHIM4Q_E0oVgJ5Rf35yPYuXT2VRjP1TiTcZ4cqkc9t-gkac4c596-8aKaxc87WA9I6mLlGrsBpbDtZ1MVyUuJNzchaBovAUC7mbGaMGi9edDILv8NcL0HfLnlCG5LxzWbcaLdsHNZihowox4gdo84rBAWtFLzLGY3oDKJ2p1usmCaxrUx8lSyFWa22OSJ9l5VXSSqenc-J6TCw1L2IUq76C2swl1VAJvtmNJ0ta3t9xiT-mSNSXp2cL6B1Y1_Q30yLIvkf0nkiRnwImc',
    'Access-api-key': "eyJpdiI6ImZET2JBQjgxVGNFdUxVeWZWazliekE9PSIsInZhbHVlIjoiZndpRW9jRk1vTDZlR1pOWEc2RkswQT09IiwibWFjIjoiNjkxNmJmYTFhMzRjOTY0YjgyNmNkY2M3MTMxMTAyMGIwZjgxMTQ3NDhkMTgyYzk5YTQ2YTE4ZDlmZDQwZGEzYSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IlNCcjFrWS9jM1B5V2R4WmV6c2wwbFE9PSIsInZhbHVlIjoiU25YdlM5QmJ0b3VaK3gvODJvbXlndz09IiwibWFjIjoiMTQ5ODFjZjkwMzg5NjBhZTQ5ZWM0MDA2OTJhZjFlODFjYWZmNDU1MGY0YTUyZGZiNjJkNTE0MWVhMzBmOTQxYSIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: ""
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage1Avaleht />} exact />;
                                                                
                                                                <Route path="*" element={<Webpage1Avaleht />} exact />;
                                        </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}